import React from 'react'

import Layout from '../components/Layout'
import Hero from '../components/Hero'

function HomePage(props) {
  return (
    // <Layout noFooter>
    <Hero />
    // </Layout>
  )
}

HomePage.Layout = Layout
HomePage.noFooter = true

export default HomePage
