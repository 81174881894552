import styled from 'styled-components'
import { ShadowStyle } from './Styles'

const HeroSubtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  text-shadow: ${ShadowStyle};
  color: var(--colorInverted);
  opacity: 0;
  @media (max-width: 768px) {
    margin-bottom: 0;
    line-height: 2;
  }
`
export default HeroSubtitle
