import styled from 'styled-components'

const CardDiv = styled.div`
  transform: skewY(-1deg);
  border: 0.3rem solid var(--accent);
  @media (max-width: 768px) {
    width: 80%;
  }
  :hover {
    /* border: 0.3rem solid var(--bg); */
  }
`

export default CardDiv
