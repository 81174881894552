import styled from 'styled-components'

const InnerHeroDiv = styled.div`
  background-color: var(--bg);
  padding: 2rem;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4rem var(--accent) solid;
    z-index: -1;
    background-color: transparent;
    top: 1rem;
    left: 1rem;
  }
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`

export default InnerHeroDiv
