import React, { useRef, useEffect } from 'react'
import { GitHub, Twitter, Send } from 'react-feather'
import { TweenMax, Power3 } from 'gsap'

import NavBar from './NavBar'
import Icon from '../css/Icon'
import { IconColor } from '../css/Styles'
import HeroTitle from '../css/HeroTitle'
import HeroSubtitle from '../css/HeroSubtitle'
import HeroDiv from '../css/HeroDiv'
import InnerHeroDiv from '../css/InnerHeroDiv'
import CardDiv from '../css/CardDiv'
import HeroList from '../css/HeroList'

export default function Hero() {
  let mainDiv = useRef(null)
  let iconsList = useRef(null)

  // const TL = new TimelineLite()

  useEffect(() => {
    mainDiv.childNodes.forEach((child, index) => {
      if (index > 1) return // Only animates first two children
      /*
        The plus index / 10 adds a 0.1 second delay for each child based on the current array item
        first child will be 0.5 + 0(index) / 10 = 0.5
        second child will be 0.5 + 1(index) / 10 = 0.6
        It adds the duration of thee first item and the delay of the next item
      */
      TweenMax.from(child, { y: 200, ease: Power3.easeIn, duration: 0.5 + index / 10, delay: 0 })
      TweenMax.to(child, { opacity: 1, ease: Power3.easeOut, duration: 0, delay: 0.5 + index / 10 })
    })
    iconsList.childNodes.forEach((child, index) => {
      TweenMax.from(child, { y: 200, ease: Power3.easeIn, duration: 0.8 + index / 8 })
      TweenMax.to(child, { opacity: 1, ease: Power3.easeOut, duration: 0, delay: index === 0 ? 0.8 : 0.8 + index / 8 })
    })
  }, [])

  return (
    <>
      <NavBar />
      <HeroDiv>
        <CardDiv>
          <InnerHeroDiv ref={el => (mainDiv = el)}>
            <HeroTitle>
              Mustafa Campwala
            </HeroTitle>
            <HeroSubtitle>
              Software Engineer
            </HeroSubtitle>
            <HeroList ref={el => (iconsList = el)}>
              <li style={{ opacity: 0 }}>
                <a href='https://github.com/mustafazc'>
                  <Icon className="iconHover">
                    <GitHub color={IconColor} />
                    <span className="assistive-text">Github Icon</span>
                  </Icon>
                </a>
              </li>
              <li style={{ opacity: 0 }}>
                <a href='https://twitter.com/mustafazc'>
                  <Icon className="iconHover">
                    <Twitter color={IconColor} />
                    <span className="assistive-text">Twitter Icon</span>
                  </Icon>
                </a>
              </li>
              <li style={{ opacity: 0 }}>
                <a href='mailto:hello@mustafazc.com'>
                  <Icon className="iconHover">
                    <Send color={IconColor} />
                    <span className="assistive-text">Email Icon</span>
                  </Icon>
                </a>
              </li>
            </HeroList>
          </InnerHeroDiv>
        </CardDiv>
      </HeroDiv>
    </>
  )
}
