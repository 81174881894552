import styled from 'styled-components'

const HeroDiv = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 90vh;
`

export default HeroDiv
