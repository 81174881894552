import styled from 'styled-components'

import { ShadowStyle } from './Styles'

const HeroTitle = styled.h1`
  font-size: 2.25rem;
  font-weight: 700;
  text-shadow: ${ShadowStyle};
  color: var(--colorInverted);
  opacity: 0;
  @media (max-width: 768px) {
    line-height: 1.25;
    font-size: 1.8rem;
  }
`

export default HeroTitle
